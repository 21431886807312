.title h1 {
    font-size: 28px;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    letter-spacing: 1.2px;
    color: #EDEDED;
    text-transform: uppercase;
    margin-bottom: 50px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
  
  
.title h3 {
    font-size: 20px;
    margin-bottom: 100px;
    font-weight: 400;
}

.title p {
    font-size: 16px;
    margin-bottom: 20px;
}